import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import {
    selectedTag, icon, tag
} from "./tag-list.module.scss"
import { getTagName, getTagIcon, getTagUrl } from "../utils/tag-util"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


const createTagButton = (t, selected) =>
    <Link to={getTagUrl(t)}
          key={t}
          className={tag + (selected === t ? " " + selectedTag : "")}>
        <FontAwesomeIcon icon={getTagIcon(t)} className={icon}/>
        {getTagName(t)} {" "}
    </Link>

const TagList = ({ tags, selected, showMain }) => {
    return (
        <>
            {
                showMain && createTagButton("main", selected)
            }
            {
                tags.filter(t => t !== "main")
                    .map(t => createTagButton(t, selected))
            }
        </>
    )
}
TagList.propTypes = {
    tags: PropTypes.array.isRequired,
    selected: PropTypes.string
}
export default TagList
