import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import ProjectTagPageInternals from "../components/project-tag-page-internals"

const sortingByPriority = function(a, b) {
    const aWeight = a.node.frontmatter.priority || 0
    const bWeight = b.node.frontmatter.priority || 0
    return bWeight - aWeight
}

const IndexPage = ({ data }) => {
    data.allMdx.edges.sort(sortingByPriority)

    return (
        <>
            <Seo title="Home"/>
            <ProjectTagPageInternals data={data}
                                     name={"Favourite Projects"}
                                     tag={"main"}
                                     displayCount={false}
                                     disableSEO={true}/>
        </>
    )
}

export default IndexPage

export const query = graphql`
  query {
    allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {frontmatter: {tags: { in: ["main"]}}} ) {
      totalCount
      edges {
        node {
          ...ProjectOverviewFragment
        }
      }
  }
  }
`
