import {
    projectListTitle,
    tagList,
    projectName
} from "./project-tag-page-internals.module.scss"
import React from "react"
import Seo from "./seo"
import TagList from "./tag-list"
import ProjectList from "./project-list"
import { getAllFeaturedTags } from "../utils/tag-util"
import { Link } from "gatsby"

const ProjectTagPageInternals = ({ data, tag, name, displayCount, disableSEO = false }) => {
    // const { totalCount } = data.allMdx

    return (
        <>
            {!disableSEO && <Seo title={name}/>}
            <section>

                <h1>
                    About me
                </h1>
                <p>
                    I'm dr. ir. Thomas Winters,
                    a
                    <Link to={"/academic"}>
                        {" "}
                        post-doctoral researcher
                        {" "}
                    </Link>
                    in
                    <Link to={"/tag/ai"}>
                        {" "}
                        artificial intelligence
                        {" "}
                    </Link>
                    and
                    <Link to={"/tag/computational-humor"}>
                        {" "}
                        computational humor
                    </Link> at
                    {" "}
                    <a href="https://www.kuleuven.be/wieiswie/nl/person/00124257" target="_blank" rel="noopener noreferrer">
                        KU Leuven
                    </a>
                    .
                </p>
            </section>
            <h1 className={projectListTitle}>
                <span className={projectName}>{name}</span>
            </h1>
            <div className={tagList}>
                <TagList tags={getAllFeaturedTags()}
                         selected={tag}
                         showMain={true}/>
            </div>
            <ProjectList projects={data.allMdx.edges.map(({ node })=>node)}/>
        </>
    )
}

export default ProjectTagPageInternals
