const { faTag, faHeart, faRobot, faLaugh, faTheaterMasks, faCode, faPencilRuler, faMusic, faPlay, faLaughBeam, faGamepad, faPhotoVideo} = require("@fortawesome/free-solid-svg-icons")

const tagDetails = {
    "main": {
        name: "Favourite",
        featured: true,
        icon: faHeart
    },
    "ai": {
        name: "Artificial Intelligence",
        featured: true,
        icon: faRobot
    },
    "computational humor": {
        name: "Computational Humor",
        featured: true,
        icon: faLaughBeam
    },
    "humor": {
        name: "Humor",
        featured: false,
        icon: faLaugh
    },
    "improv": {
        name: "Improv",
        featured: true,
        icon: faTheaterMasks
    },
    "web development": {
        name: "Web Development",
        featured: false,
        icon: faCode
    },
    "design": {
        name: "Design",
        featured: true,
        icon: faPencilRuler
    },
    "video": {
        name: "Video",
        featured: true,
        icon: faPhotoVideo
    },
    "music": {
        name: "Music",
        featured: true,
        icon: faMusic
    },
    "animation": {
        name: "Animation",
        featured: false,
        icon: faPlay
    },
    "game": {
        name: "Game",
        featured: false,
        icon: faGamepad
    },
}

const getTagName = function(tag) {
    const details = tagDetails[tag.toLowerCase()]
    if (details && details.name) {
        return details.name
    }
    return tag
}


const getTagUrl = function(tag) {
    if (tag === "main") {
        return "/"
    }
    return `/tag/${slugifyTag(tag)}`
}

const getTagIcon = function(tag) {
    const details = tagDetails[tag.toLowerCase()]
    if (details && details.icon) {
        return details.icon
    }
    return faTag
}

const getAllFeaturedTags = function() {
    return Object.keys(tagDetails).filter(t => tagDetails[t].featured)
}

const slugifyTag = function(tag) {
    return tag.toLocaleLowerCase()
        .replace(/\s+/g, "-") // Spaces with '-'
}


module.exports = { getTagName, getTagIcon, getAllFeaturedTags, getTagUrl,slugifyTag, tagDetails }


